import React from "react";
// import './Footer.css'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Popup from "../../components/Popup";
import { useState } from "react";
import Form from "../../components/Form";


function Footer() {
  const [buttonPopup, setButtonPopup] = useState(false);
  return (
    <div className="GcontainerF">
      <div className="GbtnF">
        <a href="https://opensea.io" className=" pensea">
            
        </a>
        <a href="https://discord.gg/nmgK3CY5ME" className=" iscord"></a>

        <a href="https://x.com/PanicNFTs" className=" Xx"></a>
      </div>
      
      <div className="GbtnFo">
      <Link to='/AboutUs' className="Abu">
      </Link>
          
        
        <a href="mailto:panic@panicnft.xyz" className="Don"></a>

        {/* <Link to='/QandA' className="QA"></Link> */}
      </div>
          
      <a className="popupbags" onClick={() => setButtonPopup(true)} ></a>


<Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
   <Form/>
  {/* <Forms/> */}
</Popup>

      <p className="copywrite">&copy;Hitch2024</p>
    </div>
  );
}

export default Footer;
