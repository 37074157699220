import React, { useState } from 'react';
import Send from '../components/Send Button.png';
import Success from '../components/Success Button.png';

function Form() {
  const [buttonImage, setButtonImage] = useState(Send);
  const [formData, setFormData] = useState({
    Username: '',
    Address: '',
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.Username) newErrors.Username = 'Please fill the Username field.';
    if (!formData.Address) newErrors.Address = 'Please fill the Wallet Address field.';
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      // No errors, proceed with form submission
      setButtonImage(Success);
      setErrors({});
      // Create a form element to manually submit to Formspree
      const form = document.createElement('form');
      form.action = 'https://formspree.io/f/mpwznzvz';
      form.method = 'POST';

      // Append input fields to the form
      Object.keys(formData).forEach((key) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = formData[key];
        form.appendChild(input);
      });

      document.body.appendChild(form);
      form.submit();
    } else {
      // Display errors
      setErrors(formErrors);
    }
  };

  return (
    <form className='form' onSubmit={handleSubmit}>
      <div className='username'>
        <label htmlFor='Username' className='usernametitle'>
          X Username
        </label>
        <input
          className='usernameinput'
          required
          type='text'
          placeholder='@panicNFTs'
          name='Username'
          size='10'
          value={formData.Username}
          onChange={handleInputChange}
        />
        {errors.Username && <span className='error'>{errors.Username}</span>}
      </div>
      <div className='address'>
        <label htmlFor='Address' className='addresstitle'>
          Wallet Address
        </label>
        <input
          className='addressinput'
          required
          type='text'
          placeholder='0Xdd....'
          name='Address'
          size='40'
          value={formData.Address}
          onChange={handleInputChange}
        />
        {errors.Address && <span className='error'>{errors.Address}</span>}
      </div>
      <button
        type='submit'
        style={{
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          padding: '0',
        }}
      >
        <img src={buttonImage} alt='Button Icon' className='buttonform' />
      </button>
    </form>
  );
}

export default Form;
