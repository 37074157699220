import React from "react";
// import Popup from "../../Popup";
// import './Footer.css'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
// import AboutUs from '../../About-us/AboutUs.jsx'
// import { useState } from "react";
// import Pop from "../Gift box.gif"
// import Form from "../../Form";
// import Forms from "../../Forms";

function Footer() {
  // const [buttonPopup, setButtonPopup] = useState(false);
  return (
    <>
      <div className="YcontainerF">
      
      
      <div className="YbtnF btnxs">
        <a href="https://opensea.io" className=" pensea">
            
        </a>
        <a href="https://discord.gg/nmgK3CY5ME" className=" iscord"></a>

        <a href="https://x.com/PanicNFTs" className=" Xx"></a>
      </div>
      
      <div className="YbtnFo">
      
      <Link to='/AboutUs' className="Abu">
      </Link>
        
        <a href="mailto:panic@panicnft.xyz" className="Don"></a>
        
        
      </div>


     
      {/* <a className="popupbag" onClick={() => setButtonPopup(true)} ></a>


      <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
         <Form/>
        {/* <Forms/> */}
      {/* </Popup> */} 
    </div>
      <p className="Ycopywrite">&copy;Hitch2024</p>

      
    </>
  );
}

export default Footer;
