import React from 'react'
import './styles.css'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';


function Chapter2() {
  return (
    <div className="conta2">
    <Link to="/"><div className='backhome'></div></Link>
    
    </div>
  )
}

export default Chapter2