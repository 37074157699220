import React from 'react'
import "./Popup.css"
import King from "./King 200 × 350.png"
import Queen from "./Queen 200 × 350.png"

function Popup(props) {
  return (props.trigger) ? (
    <div className='popup'>
    <div className='popup-inner'>
        <button className='close-btn' onClick={() => props.setTrigger(false)}></button>
        {props.children}
        {/* <div className='kingandqueen'> */}
        <img src={King} alt="Survivor 1" className="kingimage" />
        <img src={Queen} alt="Survivor 1" className="queenimage" />
        {/* </div> */}
    </div>
    </div>
  ) : "";
}

export default Popup